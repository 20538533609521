import React from "react";
import { graphql } from "gatsby";

import GraphQLErrorList from "../components/Blog/graphql-error-list";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";

export const query = graphql`
  query VideoTemplateQuery($id: String!) {
    video: sanityVideo(id: { eq: $id }) {
      title
      slug {
        current
      }
      videoPosterImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
      videoSource
    }
  }
`;

const VideoPostTemplate = (props) => {
  const { data, errors } = props;
  const post = data && data.video;

  return (
    <Layout>
      {errors && <SearchEngineOptimization title="GraphQL Error" />}
      {post && (
        <SearchEngineOptimization
        // title={post.seoTitle}
        // description={post.metaDescription}
        // openGraphImage={post.featuredImage}
        // twitterOpenGraphImage={post.featuredImage}
        />
      )}

      {errors && (
        <div className="container">
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      {post && (
        <section className="pb-20 md:pb-32">
          <div className="container">
            <article className="pt-12 md:pt-20 pb-20 md:pb-32 bg-white px-6 md:px-14 lg:px-22">
              <header className="mb-10 md:mb-14 md:text-center">
                <h1 className="text-gray-800 heading-two">{post.title}</h1>
              </header>
              <video
                width="100%"
                className="max-w-[635px] mx-auto"
                controls
                poster={
                  post.videoPosterImage.asset.gatsbyImageData.images.fallback
                    .src
                }
              >
                <source src={post.videoSource} type="video/mp4" />
              </video>
            </article>
          </div>
        </section>
      )}

      <CallToAction />
    </Layout>
  );
};

export default VideoPostTemplate;
